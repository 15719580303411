import { GlobalStyles } from "@mui/material";

export const GlobalStylesOverride = () => (
	<GlobalStyles
		styles={{
			"html, body": {
				padding: 0,
				margin: 0,
				fontFamily: `'Inter', sans-serif`,
			},

			"*": {
				msOverflowStyle: "none",
				boxSizing: "border-box",
			},
			material_symbols_rounded: {
				fontVariationSettings: `'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 40`,
			},
			"span.material-symbols-h3": {
				fontSize: "48px !important",
			},
			"span.material-symbols-h4": {
				fontSize: "28px !important",
			},
			"span.material-symbols-h6": {
				fontSize: "18px !important",
			},
		}}
	/>
);
