import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const SidebarNav = ({ pages, onClose }) => {
	return (
		<Box>
			<Box
				display={"flex"}
				justifyContent={"flex-end"}
				onClick={() => onClose()}>
				<IconButton>
					<CloseIcon fontSize="large" />
				</IconButton>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				paddingX={2}
				paddingBottom={2}>
				<Box alignSelf="center">
					{pages.map((item, i) => (
						<Box
							key={i}
							marginBottom={4}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center">
							<div className="material-symbols-rounded">{item.marker}</div>
							<Typography
								variant="caption"
								align="center"
								sx={{
									fontWeight: 700,
									textTransform: "uppercase",
									marginBottom: 2,
									display: "block",
								}}>
								{item.title}
							</Typography>

							<Grid
								container
								spacing={1}>
								{item.pages.map((p, i) => (
									<Grid
										item
										xs={12}
										key={i}>
										<Button
											fullWidth
											size="large"
											variant={p.important ? "contained" : "outlined"}
											color="primary"
											component="a"
											sx={{
												height: "56px",
											}}
											target={p.self}
											href={p.href}
											endIcon={
												<span className="material-symbols-rounded">
													{p.icon}
												</span>
											}
											onClick={() => onClose()}>
											{p.title}
										</Button>
									</Grid>
								))}
							</Grid>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default SidebarNav;
