import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import MangoLogo from "~gis-website/svg/logos/MangoLogo";
import Products from "../Products";

const Topbar = ({ onSidebarOpen }) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
			width={"100%"}
			height={"72px"}>
			<Box
				display={"flex"}
				alignItems={"center"}>
				<Box
					marginRight={{ xs: 1, sm: 2 }}
					display={{ xs: "flex", md: "none" }}>
					<IconButton
						onClick={onSidebarOpen}
						aria-label="Menu">
						<span className="material-symbols-rounded">menu</span>
					</IconButton>
				</Box>
				<Box
					display={"flex"}
					alignItems="baseline"
					component="a"
					href="/"
					title="Mango GIS"
					width={150}
					marginRight={1}>
					<MangoLogo />
				</Box>
			</Box>
			<Box
				display={"flex"}
				alignItems={"center"}>
				<Box
					sx={{ display: { xs: "none", md: "flex" } }}
					alignItems={"center"}>
					<Box marginX={1}>
						<Products />
					</Box>

					<Box marginX={1}>
						<Button
							variant="text"
							color="primary"
							href="https://help.mangomap.com"
							target="blank"
							size="large">
							Resources
						</Button>
					</Box>
					<Box marginX={1}>
						<Button
							name="top_pricing"
							variant="text"
							color="primary"
							href="/pricing"
							size="large">
							Pricing
						</Button>
					</Box>
					<Box marginLeft={2}>
						<Button
							variant="outlined"
							color="primary"
							href="/sign-in"
							size="large"
							endIcon={
								<span className="material-symbols-rounded">waving_hand</span>
							}>
							Sign in
						</Button>
					</Box>
					<Box marginLeft={2}>
						<Button
							variant="contained"
							color="primary"
							href="/sign-up"
							size={"large"}
							sx={{ sm: "large" }}
							endIcon={
								<span className="material-symbols-rounded">arrow_forward</span>
							}>
							Get started
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Topbar;
