import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Chip from "@mui/material/Chip";

const pricing = [
	{
		title: "Business",
		price: {
			monthly: 99,
			annual: 79,
			multi: 69,
			annual_total: 950,
			annual_gross: 1188,
		},
		features: [
			{
				title:
					"For individuals and SMEs than need easy map creation and distribution.",
				isIncluded: true,
				isImportant: true,
			},
			{
				title: "20 Maps",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Share maps publicly",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Share privately with password protection",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "All Essential Mapping Features",
				isIncluded: true,
				isImportant: false,
			},
		],
		isHighlighted: false,
		hasButton: false,
	},
	{
		title: "Enterprise",
		price: {
			annual: 159,
			monthly: 199,
			multi: 139,
			annual_total: 1910,
			annual_gross: 2388,
		},
		features: [
			{
				title:
					"For consultants and business that need control, collaboration and automation.",
				isIncluded: true,
				isImportant: true,
			},
			{
				title: "40 Maps",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Unlimited viewer seats",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Securely share maps with selected viewers",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Automate data updates",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Dedicated account manager",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Priority support",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "+ Everything in Business",
				isIncluded: true,
				isImportant: false,
			},
		],
		isHighlighted: true,
		btnText: "Start Free Trial",
		hasButton: true,
	},
	{
		title: "Agency",
		price: {
			annual: 319,
			monthly: 399,
			multi: 279,
			annual_total: 3830,
			annual_gross: 4788,
		},
		features: [
			{
				title:
					"For organisations that need total reliablity and unlimited expandibility.",
				isIncluded: true,
				isImportant: true,
			},
			{
				title: "100 Maps",
				isIncluded: true,
				isImportant: false,
			},

			{
				title: "Dedicated account manager",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "Priority support",
				isIncluded: true,
				isImportant: false,
			},
			{
				title: "+ Everything in Enterprise",
				isIncluded: true,
				isImportant: false,
			},
		],
		isHighlighted: false,
		hasButton: false,
	},
];

const PricingCards = () => {
	const theme = useTheme();
	const [pricingOption, setPricingOption] = useState("annual");

	const handleClick = (event, newPricingOption) => {
		setPricingOption(newPricingOption);
	};

	return (
		<Container>
			<Box marginBottom={4}>
				<Typography
					variant="h1"
					color="textPrimary"
					gutterBottom
					align="center"
					sx={{
						fontWeight: 700,
					}}>
					Sensibly priced web maps, no claptrap.
				</Typography>
				<Typography
					variant="h6"
					component="p"
					color="textSecondary"
					align="center">
					Mango is perfect for mapping projects of any size and length.
				</Typography>
			</Box>
			<Box width="100%">
				<Box
					display={"flex"}
					justifyContent={"center"}
					marginBottom={4}>
					<ToggleButtonGroup
						value={pricingOption}
						exclusive
						onChange={handleClick}>
						<ToggleButton
							value="monthly"
							size={"small"}
							sx={{
								backgroundColor:
									pricingOption === "monthly"
										? `${theme.palette.primary.main} !important`
										: "transparent",
								border: `1px solid ${theme.palette.primary.main}`,
							}}>
							<Typography
								variant="subtitle2"
								sx={{
									fontWeight: "medium",
									color:
										pricingOption !== "annual"
											? theme.palette.common.white
											: "primary.main",
								}}>
								Monthly
							</Typography>
						</ToggleButton>
						<ToggleButton
							value="annual"
							size={"small"}
							sx={{
								backgroundColor:
									pricingOption === "annual"
										? `${theme.palette.primary.main} !important`
										: "transparent",
								border: `1px solid ${theme.palette.primary.main}`,
							}}>
							<Typography
								variant="subtitle2"
								sx={{
									fontWeight: "medium",
									color:
										pricingOption === "annual"
											? theme.palette.common.white
											: "primary.main",
								}}>
								Annual - SAVE 20%
							</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
				<Container disableGutters>
					<Grid
						container
						spacing={2}>
						{pricing.map((item, i) => (
							<Grid
								item
								xs={12}
								md={4}
								key={i}>
								<Box
									component={Card}
									height={{ xs: "auto", md: "100%" }}
									display={"flex"}
									flexDirection={"column"}
									boxShadow={item.isHighlighted ? 4 : 3}
									borderColor={
										item.isHighlighted ? "primary.main" : "transparent"
									}
									sx={{
										backdropFilter: "blur(5px)",
									}}
									borderRadius={4}>
									<Box
										component={CardContent}
										padding={4}>
										<Box
											marginBottom={2}
											display={"flex"}
											flexDirection={"row"}
											alignContent={"center"}
											alignItems={"center"}
											justifyContent={"space-between"}>
											<Box alignSelf="flex-start">
												<Chip
													variant="outlined"
													color="primary"
													sx={{
														fontWeight: "500",
														fontSize: "16px",
														height: "36px",
														borderRadius: "4px",
													}}
													label={item.title}></Chip>
											</Box>
											<Box
												display={item.isHighlighted ? "inline" : "none"}
												alignSelf="flex-end">
												<Chip
													color="success"
													size="small"
													label="Recommended!"
												/>
											</Box>
										</Box>
										<Box>
											<Typography
												variant={"body2"}
												fontWeight={700}
												sx={{
													opacity: "35%",
													textDecoration:
														pricingOption === "annual"
															? "line-through"
															: "none",
												}}>
												{pricingOption === "annual"
													? "$" + `${item.price.monthly}` + " / MO"
													: "BILLED MONTHLY"}
											</Typography>
										</Box>
										<Box
											id="pricing box"
											marginBottom={1}
											display={"flex"}
											width={"100%"}
											justifyContent={"flex-start"}
											alignItems={"center"}>
											<Box
												display={"flex"}
												alignItems={"center"}>
												<Typography
													variant={"body2"}
													color={"primary"}>
													<Box
														component={"span"}
														fontWeight={700}>
														$
													</Box>
												</Typography>
												<Typography
													variant={"h1"}
													color={"primary"}>
													<Box component={"span"}>
														{pricingOption === "annual"
															? item.price.annual
															: item.price.monthly}
													</Box>
												</Typography>
												<Typography
													variant={"body2"}
													color={"primary"}>
													<Box
														component={"span"}
														fontWeight={700}
														marginLeft={1 / 2}>
														USD / MO
													</Box>
												</Typography>
											</Box>
										</Box>

										<Grid
											container
											spacing={1}>
											{item.features.map((feature, j) => (
												<Grid
													item
													xs={12}
													key={j}>
													<Typography
														component={"p"}
														variant={"body2"}
														align={"left"}
														style={{
															textDecoration: !feature.isIncluded
																? "line-through"
																: "none",
															fontWeight: !feature.isImportant ? 400 : 700,
														}}
														color={
															feature.isImportant ? "primary" : "textPrimary"
														}>
														{feature.title}
													</Typography>
												</Grid>
											))}
											<Grid item>
												<Box>
													<Typography
														variant={"body2"}
														fontWeight={700}>
														<Box
															component="span"
															sx={{
																opacity: "25%",
																textDecoration:
																	pricingOption === "annual"
																		? "line-through"
																		: "none",
															}}>
															{pricingOption === "annual"
																? "$" +
																	`${item.price.annual_gross.toLocaleString(
																		"en-US",
																	)}` +
																	""
																: ""}
														</Box>{" "}
														<Box
															component="span"
															sx={{
																opacity: "35%",
															}}>
															{pricingOption === "annual"
																? "$" +
																	`${item.price.annual_total.toLocaleString(
																		"en-US",
																	)}` +
																	"\xa0BILLED ANNUALLY"
																: ""}
														</Box>
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Box>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</Container>
	);
};

export default PricingCards;
