/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
	email: yup
		.string()
		.trim()
		.email("Please enter a valid email address")
		.required("Email is required."),
});

const Form = () => {
	const initialValues = {
		email: "",
	};

	const onSubmit = (values) => {
		return values;
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit,
	});

	return (
		<Box>
			<Box marginBottom={4}>
				<Typography
					sx={{
						textTransform: "uppercase",
						fontWeight: "medium",
					}}
					gutterBottom
					color={"textSecondary"}>
					Recover account
				</Typography>
				<Typography
					variant="h4"
					sx={{
						fontWeight: 700,
					}}>
					Forgot your password?
				</Typography>
				<Typography color="text.secondary">
					Enter your email address below and we'll get you back on track.
				</Typography>
			</Box>
			<form onSubmit={formik.handleSubmit}>
				<Grid
					container
					spacing={4}>
					<Grid
						item
						xs={12}>
						<Typography
							variant={"subtitle2"}
							sx={{ marginBottom: 2 }}>
							Enter your email
						</Typography>
						<TextField
							label="Email *"
							variant="outlined"
							name={"email"}
							fullWidth
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
						/>
					</Grid>
					<Grid
						container
						xs={12}>
						<Box
							display="flex"
							flexDirection={{ xs: "column", sm: "row" }}
							alignItems={{ xs: "stretched", sm: "center" }}
							justifyContent={"space-between"}
							width={"100%"}
							maxWidth={600}
							margin={"0 auto"}>
							<Box marginBottom={{ xs: 1, sm: 0 }}>
								<Button
									size={"large"}
									variant={"outlined"}
									component={Link}
									href={"/sign-in"}>
									Back to sign in
								</Button>
							</Box>
							<Button
								size={"large"}
								variant={"contained"}
								type={"submit"}>
								Send reset link
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default Form;
