import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import WithLayout from "~gis-website/WithLayout";
import Container from "~gis-website/common/Container";
import Minimal from "~gis-website/layouts/Minimal";
import MangoLogo from "~gis-website/svg/logos/MangoLogo";
import { FormLogos } from "../globals";

import { SignUpForm } from "./components";
const SignUp = () => {
	return (
		<WithLayout
			component={App}
			layout={Minimal}
		/>
	);
};

const App = () => {
	return (
		<Container
			minHeight={"100vh"}
			height={"100%"}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"space-around"}>
			<Box
				display={"flex"}
				flexDirection={"row"}
				alignItems={"flex-start"}
				justifyContent={"space-between"}>
				<Link href="/">
					<Box
						alignItems="center"
						title="Mango GIS"
						width={200}>
						<MangoLogo />
					</Box>
				</Link>
			</Box>
			<Grid
				container
				display={"flex"}
				flexDirection={"row"}
				alignItems={"flex-start"}
				justifyContent={"space-around"}>
				<Grid
					item
					xs={12}
					marginBottom={4}>
					<Typography
						variant="h3"
						align={"center"}>
						Get started with Mango
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					display={"flex"}
					alignContent={"flex-end"}>
					<SignUpForm />
				</Grid>
			</Grid>
			<Box>
				<FormLogos />
			</Box>
		</Container>
	);
};

export default SignUp;
