/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
	email: yup
		.string()
		.trim()
		.email("Please enter a valid email address")
		.required("We need a valid email address. You’ll sign in with this email."),
});

const Form = ({ GridItemPartnersBlock, name }) => {
	const initialValues = {
		email: "",
	};

	const onSubmit = (values) => {
		return values;
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit,
	});

	return (
		<Box
			component={Card}
			maxWidth={"100%"}
			boxShadow={4}
			bgcolor={"background.level1"}
			zIndex={10}>
			<CardContent>
				<Box
					component={"form"}
					noValidate
					autoComplete="off"
					sx={{
						"& .MuiInputBase-input.MuiOutlinedInput-input": {
							bgcolor: "background.level1",
						},
					}}>
					<form onSubmit={formik.handleSubmit}>
						<Grid
							container
							spacing={2}>
							<Grid
								item
								xs={12}
								lg={7}>
								<TextField
									label="Work email"
									variant="outlined"
									name={"email"}
									fullWidth
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								lg={5}>
								<Box
									name={name}
									component={Button}
									variant="contained"
									color="primary"
									size="large"
									height={54}
									fullWidth
									endIcon={
										<span className="material-symbols-rounded">
											arrow_forward
										</span>
									}>
									<Typography
										variant="body1"
										component="p"
										color="white">
										Start your free trial
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								md={3}
								lg={3}>
								<Typography
									variant="body2"
									component="p"
									align="center"
									color="textSecondary">
									&#x2714; 30-day free trial
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								md={3}
								lg={3}>
								<Typography
									variant="body2"
									align="center"
									component="p"
									color="textSecondary">
									&#x2714; Instant access
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								md={3}
								lg={3}>
								<Typography
									variant="body2"
									align="center"
									component="p"
									color="textSecondary">
									&#x2714; Fully featured
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								md={3}
								lg={3}>
								<Typography
									variant="body2"
									align="center"
									component="p"
									color="textSecondary">
									&#x2714; No credit card
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}>
								<Typography
									variant="caption"
									component="p"
									color="textSecondary"
									align="center">
									By submitting your email you agree to our{" "}
									<Link
										target="_blank"
										href="/privacy"
										color="textSecondary">
										Privacy Policy
									</Link>{" "}
									and{" "}
									<Link
										target="_blank"
										href="/terms"
										color="textSecondary">
										Terms of Service
									</Link>
									.
								</Typography>
							</Grid>
						</Grid>
					</form>
				</Box>
				<Box
					display="flex"
					flexWrap="wrap"
					justifyContent={"space-between"}>
					{GridItemPartnersBlock && <GridItemPartnersBlock />}
				</Box>
			</CardContent>
		</Box>
	);
};

export default Form;
