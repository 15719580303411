/* eslint-disable react/no-unescaped-entities */
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

const FormLogos = () => {
	const theme = useTheme();

	return (
		<Box
			display={"flex"}
			flexDirection={"row"}
			flexWrap={"wrap"}
			alignSelf={"center"}
			marginX={"auto"}
			justifyContent={"space-evenly"}>
			{[
				"../new-mangomap-assets/images/sensar.png",
				"../new-mangomap-assets/svg/gis4ag.svg",
				"../new-mangomap-assets/images/geotech-info.png",
				"../new-mangomap-assets/images/plumas.png",
			].map((item, i) => (
				<Box
					flexDirection={{ xs: "column", md: "row" }}
					maxWidth={105}
					alignSelf={"center"}
					marginX={2}
					marginTop={2}
					key={i}>
					<Box
						component="img"
						marginX={"auto"}
						width={"100%"}
						alignSelf={"center"}
						src={item}
						alt="Mango GIS: loved by GIS professionals world-wide."
						sx={{
							filter:
								theme.palette.mode === "dark"
									? "brightness(0) invert(0.7)"
									: "none",
						}}
					/>
				</Box>
			))}
		</Box>
	);
};
export default FormLogos;
