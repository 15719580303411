const MangoLogo = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 270 73">
			<path
				fill="#FF5722"
				d="M31.82 63.7a31.83 31.83 0 0 0 31.82-31.85 31.83 31.83 0 1 0-31.82 31.84Z"
			/>
			<path
				fill="#FF9800"
				d="M21.97 53.76a21.96 21.96 0 0 0 21.98-21.94A21.96 21.96 0 0 0 21.97 9.87 21.96 21.96 0 0 0 0 31.82a21.96 21.96 0 0 0 21.97 21.94Z"
			/>
			<path
				fill="url(#a)"
				d="M60.48 17.98c-.88 0-1.7-.04-4.22.33A31.5 31.5 0 0 0 42.4 24.1c-15.24 10.98-22.5 33.07-23.11 35l-.03.09-.02.04.01-.03.03-.1c.7-2.39 10.97-38.82-13.7-41.05-.77-.07-1.56-.11-2.4-.11A31.84 31.84 0 0 0 31.8 63.69a31.83 31.83 0 0 0 28.67-45.71Z"
			/>
			<path
				fill="#263238"
				d="M112.02 20.26 97.7 39.85h-1.63l-14-19.66V53h-8.29V7.89h9.55l13.74 19.4 13.75-19.4h9.48V53h-8.28V20.26Zm41.62.9h7.54v31.76h-7.41l-.39-4.63c-1.8 3.73-6.76 5.54-10.3 5.6-9.41.07-16.54-5.24-16.54-16.39 0-10.95 7.45-17.18 16.73-17.11 4.25 0 8.3 2 10.11 5.16l.26-4.39ZM134.4 37.5c0 6.06 4.35 9.18 9.57 9.18 12.37 0 12.37-19.27 0-19.27-5.21 0-9.57 4.04-9.57 10.1Zm55 15.51V36.4c0-4.83-2.03-8.76-7.06-8.76-4.83 0-7.05 4.32-7.05 9.15V53h-8.46l.03-32.43h7.02l.53 4.84c3.22-3.16 6.76-4.84 10.75-4.84 7.48 0 12.7 5.53 12.7 15.51V53h-8.46Zm45.12-16.77c0-8.44-6.84-16.1-16.05-16.1-8.89 0-16.1 6.18-16.1 16.1 0 9.92 6.18 15.98 16.1 15.98 3.94 0 8.06 2 8.06 6.37 0 4.38-3.54 6.83-8.06 6.83-4.5 0-7.92-2.64-7.92-6.77h-8.46c0 8.57 7.17 14.11 16.38 14.11 9.22 0 15.92-5.4 15.92-14.17 0-4.06-1.3-7.86-6.57-10.5 5.21-2.38 6.7-7.92 6.7-11.85Zm-16.05 8.82c-4.57 0-8.3-3.28-8.3-8.82 0-5.54 3.73-8.96 8.3-8.96 4.52 0 8.25 3.48 8.25 8.96s-3.74 8.82-8.25 8.82Zm51.3-8.96c0 9.14-5.66 16.92-15.97 16.92-10.3 0-16.46-7.78-16.46-16.93 0-9.08 6.28-16.19 16.4-16.19 10.11 0 16.03 7.11 16.03 16.2Zm-24.6.36c0 4.83 2.9 9.34 8.63 9.34 5.74 0 8.63-4.51 8.63-9.34 0-4.77-3.35-9.4-8.63-9.4-5.67 0-8.63 4.63-8.63 9.4Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1="0"
					x2="63.64"
					y1="40.81"
					y2="40.81"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#8BC34A" />
					<stop
						offset=".39"
						stopColor="#79B041"
					/>
					<stop
						offset="1"
						stopColor="#558B2F"
					/>
				</linearGradient>
			</defs>
		</svg>
	);
};

export default MangoLogo;
