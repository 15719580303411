import { dark as darkBlue, light as lightBlue } from "./palette--blue";
import { dark as darkGreen, light as lightGreen } from "./palette--green";
import { dark as darkIndigo, light as lightIndigo } from "./palette--indigo";
import { dark as darkOrange, light as lightOrange } from "./palette--orange";
import { dark as darkPink, light as lightPink } from "./palette--pink";

const palette = (themeMode = "light", paletteType = "orange") => {
	if (paletteType === "blue") {
		return themeMode === "dark" ? darkBlue : lightBlue;
	} else if (paletteType === "indigo") {
		return themeMode === "dark" ? darkIndigo : lightIndigo;
	} else if (paletteType === "pink") {
		return themeMode === "dark" ? darkPink : lightPink;
	} else if (paletteType === "orange") {
		return themeMode === "dark" ? darkOrange : lightOrange;
	}

	return themeMode === "dark" ? darkGreen : lightGreen;
};

export default palette;
