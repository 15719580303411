/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
	email: yup
		.string()
		.trim()
		.email("Please enter a valid email address")
		.required("Email is required."),
	password: yup
		.string()
		.required("Please specify your password")
		.min(8, "The password should have at minimum length of 8"),
});

const Form = () => {
	const initialValues = {
		email: "",
		password: "",
	};

	const onSubmit = (values) => {
		return values;
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit,
	});

	return (
		<Box>
			<Box marginBottom={4}>
				<Typography
					sx={{
						textTransform: "uppercase",
						fontWeight: "medium",
					}}
					gutterBottom
					color={"textSecondary"}>
					Login
				</Typography>
				<Typography
					variant="h4"
					sx={{
						fontWeight: 700,
					}}>
					Welcome back
				</Typography>
				<Typography color="text.secondary">
					Login to manage your account.
				</Typography>
			</Box>
			<form onSubmit={formik.handleSubmit}>
				<Grid
					container
					spacing={4}
					sx={{
						"& .MuiInputBase-input.MuiOutlinedInput-input": {
							bgcolor: "background.level1",
						},
					}}>
					<Grid
						item
						xs={12}>
						<Typography
							variant={"subtitle2"}
							sx={{ marginBottom: 2 }}>
							Enter your email
						</Typography>
						<TextField
							label="Email *"
							variant="outlined"
							name={"email"}
							fullWidth
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
						/>
					</Grid>
					<Grid
						item
						xs={12}>
						<Box
							display="flex"
							flexDirection={{ xs: "column", sm: "row" }}
							alignItems={{ xs: "stretched", sm: "center" }}
							justifyContent={"space-between"}
							width={"100%"}
							marginBottom={2}>
							<Box marginBottom={{ xs: 1, sm: 0 }}>
								<Typography variant={"subtitle2"}>
									Enter your password
								</Typography>
							</Box>
							<Typography variant={"subtitle2"}>
								<Link
									component={"a"}
									color={"primary"}
									href={"/passwords/new"}
									underline={"none"}>
									Forgot your password?
								</Link>
							</Typography>
						</Box>
						<TextField
							label="Password *"
							variant="outlined"
							name={"password"}
							type={"password"}
							fullWidth
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
						/>
					</Grid>
					<Grid
						item
						container
						xs={12}>
						<Box
							display="flex"
							flexDirection={{ xs: "column", sm: "row" }}
							alignItems={{ xs: "stretched", sm: "center" }}
							justifyContent={"space-between"}
							width={"100%"}
							maxWidth={600}
							margin={"0 auto"}>
							<Box marginBottom={{ xs: 1, sm: 0 }}>
								<Typography variant={"subtitle2"}>
									Don't have an account yet?{" "}
									<Link
										component={"a"}
										color={"primary"}
										href={"/sign-up"}
										underline={"none"}>
										Sign up here.
									</Link>
								</Typography>
							</Box>
							<Button
								size={"large"}
								variant={"contained"}
								type={"submit"}>
								Login
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default Form;
