import { PaletteOptions } from "@mui/material";

export const light: PaletteOptions = {
	alternate: {
		main: "#f7f9fc",
		dark: "#edf1f7",
	},
	mode: "light",
	primary: {
		main: "#66800B",
		light: "#879A39",
		dark: "#435700",
		contrastText: "#fff",
	},
	secondary: {
		light: "#D14D41",
		main: "#AF3029",
		dark: "#76000D",
		contrastText: "rgba(0, 0, 0, 0.87)",
	},
	text: {
		primary: "#2d3748",
		secondary: "#646e73",
	},
	divider: "rgba(0, 0, 0, 0.12)",
	background: {
		paper: "#fff",
		default: "#fff",
		level2: "#f5f5f5",
		level1: "#fff",
	},
};

export const dark: PaletteOptions = {
	alternate: {
		main: "#1a2138",
		dark: "#151a30",
	},
	common: {
		black: "#000",
		white: "#fff",
	},
	mode: "dark",
	primary: {
		main: "#205EA6",
		light: "#4385BE",
		dark: "#003C7A",
		contrastText: "#fff",
	},
	secondary: {
		light: "#D0A215",
		main: "#AD8301",
		dark: "#745600",
		contrastText: "rgba(0, 0, 0, 0.87)",
	},
	text: {
		primary: "#EEEEEF",
		secondary: "#AEB0B4",
	},
	divider: "rgba(255, 255, 255, 0.12)",
	background: {
		paper: "#222B45",
		default: "#222B45",
		level2: "#333",
		level1: "#2D3748",
	},
};
