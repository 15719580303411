import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import MangoIcon from "~gis-website/svg/logos/MangoIcon";

const Footer = () => (
	<Grid
		container
		spacing={2}
		position="relative">
		<Grid
			item
			xs={12}
			paddingY={2}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				width={"100%"}
				flexDirection={{ xs: "column", lg: "row" }}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					flexDirection={{ xs: "column", lg: "row" }}>
					<Box
						display={"flex"}
						alignItems={"center"}
						paddingBottom={2}>
						<MangoIcon />
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						paddingBottom={2}>
						<Typography
							marginX={2}
							align={"center"}
							variant={"caption"}
							color="textSecondary">
							&copy; Mangomap LIMITED. 1 Victoria Street, Bristol BS1 6AA, UK.
							+44 117 230 1999
						</Typography>
					</Box>
				</Box>
				<Box
					alignSelf="center"
					display="flex"
					flexWrap={"wrap"}
					flexDirection={"row"}
					alignItems={"center"}
					justifyContent={"space-evenly"}>
					<Box marginRight={2}>
						<Button
							name="Footer Sign-in"
							size="small"
							variant="outlined"
							href="/sign-in"
							sx={{ fontWeight: "500" }}
							endIcon={
								<span className="material-symbols-rounded">waving_hand</span>
							}>
							Sign in
						</Button>
					</Box>
					<Box>
						<Button
							name="Footer Sign-up"
							size="small"
							variant="contained"
							href="/sign-up"
							fullWidth
							endIcon={
								<span className="material-symbols-rounded">arrow_forward</span>
							}>
							Get started
						</Button>
					</Box>
				</Box>
			</Box>
		</Grid>
		<Grid
			item
			xs={12}>
			<Box
				alignSelf="center"
				display="flex"
				flexWrap={"wrap"}
				flexDirection={"row"}
				alignItems={"center"}
				justifyContent={"center"}
				marginY={2}>
				<Box marginRight={1}>
					<Button
						variant="text"
						color="primary"
						component="a"
						href="https://uptime.com/statuspage/mangomap"
						target="blank"
						size="small"
						sx={{ fontWeight: "500" }}
						endIcon={<span className="material-symbols-rounded">dns</span>}>
						Status
					</Button>
				</Box>
				<Box marginRight={1}>
					<Button
						variant="text"
						color="primary"
						component="a"
						href="https://help.mangomap.com"
						target="blank"
						size="small"
						sx={{ fontWeight: "500" }}>
						Resources
					</Button>
				</Box>
				<Box marginRight={4}>
					<Button
						name="Footer Pricing"
						size="small"
						variant="text"
						color="primary"
						href="/pricing"
						sx={{ fontWeight: "500" }}>
						Pricing
					</Button>
				</Box>
				<Box marginRight={1}>
					<Button
						name="Footer Pricing"
						size="small"
						variant="text"
						color="primary"
						href="/terms"
						sx={{ fontWeight: "500" }}>
						Terms of Service
					</Button>
				</Box>
				<Box marginRight={1}>
					<Button
						name="Footer Pricing"
						size="small"
						variant="text"
						color="primary"
						href="/privacy"
						sx={{ fontWeight: "500" }}>
						Privacy Policy
					</Button>
				</Box>
				<Box>
					<Button
						name="Footer Pricing"
						size="small"
						variant="text"
						color="primary"
						href="/security"
						sx={{ fontWeight: "500" }}>
						Data Security
					</Button>
				</Box>
			</Box>
			<Box>
				<Typography
					align={"center"}
					component="p"
					variant={"caption"}
					color="textSecondary">
					When you visit our site, we or our authorized providers may use
					cookies to enhance your experience, improve speed, security, and for
					marketing purposes.
				</Typography>
			</Box>
		</Grid>
	</Grid>
);

export default Footer;
