import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Features = () => {
	const theme = useTheme();

	return (
		<Box>
			<Box marginBottom={4}>
				<Typography
					sx={{
						textTransform: "uppercase",
						fontWeight: "medium",
					}}
					gutterBottom
					color={"primary.light"}
					align={"center"}>
					ONLINE GIS TOOLS
				</Typography>
				<Box
					component={Typography}
					fontWeight={700}
					variant={"h3"}
					align={"center"}>
					Essential features
				</Box>
			</Box>
			<Grid
				container
				spacing={2}>
				{[
					{
						title: "Visualisation and GIS analysis tools",
						subtitle:
							"Make pretty maps, pretty fast, with queries and spatial analysis tools",
						button:
							"https://help.mangomap.com/hc/en-us/sections/360003500351-Map-Search-and-Feature-Lookup",
					},
					{
						title: "Symbology builder",
						subtitle:
							"Build complex symbology styles to reuse across any map or dataset. Thematic, category, heatmaps, bubble maps, and custom coded graphic styles.",
						button:
							"https://help.mangomap.com/hc/en-us/sections/360003473552-Layer-visualisation-styles",
					},
					{
						title: "Customisation",
						subtitle:
							"Your domain, your logo, your language. Make maps speak your language and publish with your branding.",
						button:
							"https://help.mangomap.com/hc/en-us/sections/360003530232-Map-layout-and-display",
					},
					{
						title: "Supported data formats",
						subtitle:
							"All major spatial formats: Esri™ Shapefile, Mapinfo® Tab, KML, CSV, FileGeodatabase, GeoTIFF Raster, GeoJSON, and WMS 1.3",
						button:
							"https://help.mangomap.com/hc/en-us/sections/360003473652-Uploading-data-to-Mango",
					},
					{
						title: "Unlimited datasets",
						subtitle:
							"Upload and sync as many datasets as your storage quota allows. Need more? Data packs available.",
						button:
							"https://help.mangomap.com/hc/en-us/categories/360001400751-The-Mango-manual",
					},
					{
						title: "Unlimited layers",
						subtitle:
							"Need more layers? Just add them. Use data any number of times in any number of maps.",
						button:
							"https://help.mangomap.com/hc/en-us/categories/360001400751-The-Mango-manual",
					},
				].map((item, i) => (
					<Grid
						item
						xs={12}
						md={4}
						key={i}>
						<Box
							display={"block"}
							width={"100%"}
							height={"100%"}
							sx={{
								transition: "all .2s ease-in-out",
								"&:hover": {
									transform: `translateY(-${theme.spacing(1)})`,
								},
							}}>
							<Box
								component={Card}
								width={"100%"}
								height={"100%"}
								display={"flex"}
								flexDirection={"column"}
								data-aos={"fade-up"}
								borderRadius={4}
								boxShadow={4}
								sx={{
									backdropFilter: "blur(4px)",
								}}>
								<Box
									component={CardContent}
									padding={4}>
									<Box
										component={Typography}
										variant={"h5"}
										gutterBottom
										fontWeight={500}>
										{item.title}
									</Box>
									<Typography color="text.secondary">
										{item.subtitle}
									</Typography>
								</Box>
								<Box flexGrow={1} />
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default Features;
