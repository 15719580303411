/* eslint-disable react/no-unescaped-entities */

import Button, { ButtonProps } from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const SignUpButton = ({ ctaText = "Get started", ctaColor = "primary" }) => {
	return (
		<Grid
			container
			spacing={2}>
			<Grid
				item
				xs={12}>
				<Button
					variant={"contained"}
					color={ctaColor as ButtonProps["color"]}
					size={"large"}
					fullWidth
					sx={{ height: "64px" }}
					endIcon={
						<span className="material-symbols-rounded">arrow_forward</span>
					}
					href="/sign-up">
					<Typography
						variant="body1"
						component="h3"
						color="black"
						sx={{ fontWeight: "700" }}>
						{ctaText}
					</Typography>
				</Button>
			</Grid>
		</Grid>
	);
};

export default SignUpButton;
