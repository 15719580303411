import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { SignUpButton } from "../../../globals";

const CTAReviews = () => {
	const theme = useTheme();

	return (
		<Box>
			<Grid
				container
				spacing={2}
				marginBottom={4}>
				{[
					{
						feedback:
							"It has some really great functionality that makes it easy to visualize data for the client, and allows for ease of distribution access for our clients.",
						name: "Jake Tanner",
						title: "Lead GIS Developer/Analyst, Burgex, Inc.",
						avatar: "new-mangomap-assets/svg/burgex.svg",
					},
					{
						feedback:
							"The ability to lock down maps with a single password is a hugely important feature, especially when building out prototypes or when needing to keep maps private.",
						name: "Dr. Chris Green",
						title: "Senior Epidemiologist, Winnipeg Regional Health Authority ",
						avatar: "new-mangomap-assets/svg/wrha.svg",
					},
					{
						feedback:
							"I like the analogy that Mango use: sometimes it’s better to use a scalpel than a swiss army knife.",
						name: "Phil Henry",
						title: "Business Geographics & Qikmaps",
						avatar: "/new-mangomap-assets/images/busgeo.png",
					},
				].map((item, i) => (
					<Grid
						item
						xs={10}
						sm={6}
						md={4}
						key={i}
						marginX={"auto"}>
						<Box
							bgcolor={"background.level1"}
							width={"100%"}
							height={"100%"}
							data-aos={"fade-up"}
							component={Card}
							display={"flex"}
							flexDirection={"column"}>
							<Box component={CardContent}>
								<Box
									marginBottom={1}
									display={"flex"}
									flexDirection={"row"}
									alignItems={"center"}
									justifyContent={"space-between"}>
									<Box
										display={"flex"}
										flexDirection={"row"}
										alignItems={"center"}>
										{[1, 2, 3, 4, 5].map((item) => (
											<Box
												key={item}
												color={theme.palette.secondary.dark}>
												<svg
													width={18}
													height={18}
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 20 20"
													fill="currentColor">
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
											</Box>
										))}
									</Box>
									<Box
										maxWidth={120}
										width={80}
										maxHeight={50}>
										<Box
											component="img"
											width={80}
											height={"auto"}
											src={item.avatar}
											alt={`${item.feedback} ${item.name} ${item.title}`}
											sx={{
												filter:
													theme.palette.mode === "dark"
														? "brightness(0) invert(0.7)"
														: "none",
											}}
										/>
									</Box>
								</Box>
								<Typography
									align={"left"}
									color="textSecondary"
									fontSize={{ xs: "90%", sm: "100%" }}>
									{item.feedback}
								</Typography>
							</Box>
							<Box flexGrow={1} />

							<CardActions>
								<ListItem
									component="div"
									sx={{ padding: 1 }}>
									<ListItemText
										sx={{ margin: 0 }}
										primary={item.name}
										secondary={item.title}
									/>
								</ListItem>
							</CardActions>
						</Box>
					</Grid>
				))}
			</Grid>
			<Box marginBottom={8}>
				<Container maxWidth="md">
					<Typography
						component="h2"
						variant="h1"
						color="white"
						gutterBottom
						align="center"
						sx={{
							fontWeight: 700,
							marginBottom: 2,
						}}>
						Try Mango today with a no-obligation free trial.
					</Typography>
				</Container>
			</Box>
			<Container maxWidth="xs">
				<SignUpButton ctaColor="secondary" />
				<Box
					display={"flex"}
					alignContent={"center"}
					justifyContent={"space-evenly"}>
					<Box
						display="flex"
						flexWrap="wrap"
						flexDirection="row"
						alignItems="center">
						<span
							style={{ color: "white" }}
							className="material-symbols-rounded material-symbols-h6">
							check
						</span>
						<Typography
							variant="caption"
							align="center"
							component="p"
							color="white">
							30-DAY FREE TRIAL
						</Typography>
					</Box>
					<Box
						display="flex"
						flexWrap="wrap"
						flexDirection="row"
						alignItems="center"
						paddingY={1}>
						<span
							style={{ color: "white" }}
							className="material-symbols-rounded material-symbols-h6">
							check
						</span>
						<Typography
							variant="caption"
							align="center"
							component="p"
							color="white">
							NO CREDIT CARD REQUIRED
						</Typography>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default CTAReviews;
