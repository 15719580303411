import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import Container from "~gis-website/common/Container";
import Main from "~gis-website/layouts/Main";
import WithLayout from "~gis-website/WithLayout";
const NotFound = () => {
	return (
		<WithLayout
			component={App}
			layout={Main}
		/>
	);
};

const App = () => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"), {
		defaultMatches: true,
	});

	return (
		<Box
			minHeight={"calc(100vh - 64px - 183px)"}
			height={"100%"}
			display={"flex"}
			alignItems={"center"}
			bgcolor={theme.palette.alternate.main}>
			<Container>
				<Grid
					container
					spacing={6}>
					<Grid
						item
						container
						alignItems={"center"}
						justifyContent={"center"}
						xs={12}
						md={6}>
						<Box>
							<Typography
								variant="h1"
								component={"h1"}
								align={isMd ? "left" : "center"}
								sx={{ fontWeight: 700 }}>
								404
							</Typography>
							<Typography
								variant="h6"
								component="p"
								color="textSecondary"
								align={isMd ? "left" : "center"}>
								Oops! Looks like you followed a bad link.
								<br />
							</Typography>
							<Box
								marginTop={4}
								display={"flex"}
								justifyContent={{ xs: "center", md: "flex-start" }}>
								<Button
									component={Link}
									variant="contained"
									color="primary"
									size="large"
									href={"/"}>
									Back home
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default NotFound;
