import Box from "@mui/material/Box";

import Container from "~gis-website/common/Container";
import Main from "~gis-website/layouts/Main";
import WithLayout from "~gis-website/WithLayout";

import { Form } from "./components";
const ForgotPassword = () => {
	return (
		<WithLayout
			component={App}
			layout={Main}
		/>
	);
};

const App = () => {
	return (
		<Box
			position={"relative"}
			minHeight={"calc(100vh - 247px)"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			height={"100%"}>
			<Container maxWidth={600}>
				<Form />
			</Container>
		</Box>
	);
};

export default ForgotPassword;
