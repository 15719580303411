import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Container from "~gis-website/common/Container";
import { SignUpButton } from "../globals";
import Main from "~gis-website/layouts/Main";
import WithLayout from "~gis-website/WithLayout";

import { Features, FranchiseCTA, Partners, PricingCards } from "./components";
const Pricing = () => {
	return (
		<WithLayout
			component={App}
			layout={Main}
		/>
	);
};

const App = () => {
	return (
		<Box>
			<Box
				maxWidth={"100%"}
				sx={{
					marginTop: "-100px",
					backgroundImage:
						'url("./new-mangomap-assets/images/topographic.jpg"),url("./new-mangomap-assets/images/stipple_1.webp")',
					backgroundBlendMode: "screen",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					width: "100vw",
					height: "calc(100vh + 100px)",
					display: "block",
					position: "fixed",
					opacity: "10%",
				}}
			/>
			<Box
				position={"relative"}
				minHeight={450}
				overflow={"hidden"}
				zIndex="1">
				<Container
					position={"relative"}
					zIndex={4}>
					<PricingCards />
				</Container>
				<Partners />
				<Container>
					<FranchiseCTA />
				</Container>
			</Box>
			<Container>
				<Features />
			</Container>
			<Container>
				<Grid
					xs={12}
					container
					spacing={2}
					display="flex"
					flexDirection="column"
					alignItems="center"
					flexWrap="wrap"
					justifyContent={"center"}
					marginX="auto"
					marginBottom={4}>
					<Grid
						item
						md={12}
						position={"relative"}
						zIndex={4}>
						<Typography
							variant="h1"
							color="textPrimary"
							gutterBottom
							align="center"
							sx={{
								fontWeight: 700,
								marginBottom: 2,
							}}>
							Ready to get started?
						</Typography>
					</Grid>
					<Grid
						item
						md={12}
						position={"relative"}
						zIndex={4}>
						<SignUpButton ctaText="Create a free account" />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Pricing;
