import { PaletteOptions } from "@mui/material";

export const light: PaletteOptions = {
	alternate: {
		main: "#f7f9fc",
		dark: "#edf1f7",
	},
	mode: "light",
	primary: {
		main: "#ff5621",
		light: "#D14D41",
		dark: "#7F190F",
		contrastText: "#fff",
	},
	secondary: {
		light: "#62FE7A",
		main: "#0BDC2D",
		dark: "#02B749",
		contrastText: "rgba(0, 0, 0, 0.87)",
	},
	text: {
		primary: "#334046",
		secondary: "#3F565F",
		light: "#646e7322",
	},
	divider: "rgba(0, 0, 0, 0.12)",
	background: {
		paper: "#F3F8F8",
		default: "#FFFCF022",
		level2: "#F2F0E5",
		level1: "#fff",
		none: "transparent",
	},
	black: {
		main: "#100F0F",
	},
	mangoRed: {
		main: "#FF5621",
		dark: "#CC451A",
	},
	mangoOrange: {
		main: "#FF9700",
		dark: "#E57C00",
		light: "#FEA649",
	},
	mangoGreen: {
		main: "#62FE7A",
		dark: "#42DB59",
	},
};

export const dark: PaletteOptions = {
	alternate: {
		main: "#1a2138",
		dark: "#151a30",
	},
	common: {
		black: "#000",
		white: "#fff",
	},
	mode: "dark",
	primary: {
		main: "#AF3029",
		light: "#D14D41",
		dark: "#76000D",
		contrastText: "#fff",
	},
	secondary: {
		light: "#FFEA41",
		main: "#FFE102",
		dark: "#DBBE01",
		contrastText: "rgba(0, 0, 0, 0.87)",
	},
	text: {
		primary: "#EEEEEF",
		secondary: "#AEB0B4",
	},
	divider: "rgba(255, 255, 255, 0.12)",
	background: {
		paper: "#222B45",
		default: "#222B45",
		level2: "#333",
		level1: "#2D3748",
	},
};
