import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";

const AddOns = () => {
	const theme = useTheme();

	return (
		<Box>
			<Box marginBottom={{ xs: 4, sm: 8, md: 12 }}>
				<Typography
					variant="h6"
					component="h6"
					color="primary.light"
					align="center">
					EXPERIENCE MATTERS
				</Typography>
				<Box
					component={Typography}
					fontWeight={700}
					variant={"h3"}
					align={"center"}>
					Our proven track record
				</Box>
			</Box>

			<Box marginY={8}>
				<Grid
					container
					spacing={2}>
					{[
						{
							title: 10,
							subtitle: "Used and trusted by GIS users worldwide.",
							suffix: " years",
						},
						{
							title: 250,
							subtitle: "...and counting\u2014this is not our first rodeo.",
							suffix: "K+ maps",
						},
						{
							title: 99.98,
							subtitle: "Keeping your maps online, all the time.",
							suffix: "% uptime",
						},
					].map((item, i) => (
						<Grid
							key={i}
							item
							xs={12}
							md={4}>
							<Typography
								variant="h3"
								align={"center"}
								gutterBottom>
								<Box
									color={"primary.main"}
									fontWeight={600}>
									<InView triggerOnce>
										{({ inView, ref }) => (
											<div ref={ref}>
												<CountUp
													decimals={item.title == 99.98 ? 2 : 0}
													redraw={false}
													end={inView ? item.title : 0}
													start={0}
													suffix={item.suffix}
												/>
											</div>
										)}
									</InView>
								</Box>
							</Typography>
							<Typography
								color="text.secondary"
								align={"center"}
								component="p">
								{item.subtitle}
							</Typography>
						</Grid>
					))}
				</Grid>
			</Box>

			<Grid
				container
				spacing={2}>
				{[
					{
						title: "Always up-to-date",
						subtitle:
							"Data updates appear on all maps, instantly. Sync data from a local folder.",
						icon: "sync",
					},
					{
						title: "Collaborative",
						subtitle:
							"Assign teammates as Admins or Editors, or invite clients to contribute and maintain maps.",
						icon: "diversity_2",
					},
					{
						title: "Share without limits",
						subtitle:
							"Share maps securely with anyone inside or outside your organisation.",
						icon: "share",
					},
					{
						title: "Dedicated Support",
						subtitle:
							"Prompt and personal support from consistent specialists and a comprehensive self-service support centre.",
						icon: "sentiment_very_satisfied",
					},
				].map((item, i) => (
					<Grid
						item
						xs={12}
						md={3}
						key={i}>
						<Box
							display={"block"}
							width={"100%"}
							height={"100%"}
							sx={{
								textDecoration: "none",
								transition: "all .2s ease-in-out",
								"&:hover": {
									transform: `translateY(-${theme.spacing(1 / 2)})`,
								},
							}}>
							<Box
								component={Card}
								width={"100%"}
								height={"100%"}
								data-aos={"fade-up"}
								borderRadius={3}
								flexDirection={"column"}
								display={"flex"}
								boxShadow={4}>
								<Box
									component={CardContent}
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}>
									<Box
										component={Avatar}
										width={64}
										height={64}
										marginBottom={2}
										color={theme.palette.primary.contrastText}
										bgcolor={`${theme.palette.primary.main}`}>
										<span className="material-symbols-rounded material-symbols-h4">
											{item.icon}
										</span>
									</Box>
									<Box
										component={Typography}
										variant={"h5"}
										gutterBottom
										fontWeight={500}
										align={"center"}>
										{item.title}
									</Box>
									<Typography
										variant={"body1"}
										component={"p"}
										align={"center"}
										color="textPrimary">
										{item.subtitle}
									</Typography>
								</Box>
								<Box flexGrow={1} />
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default AddOns;
