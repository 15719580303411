/* eslint-disable react/no-unescaped-entities */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";

const Confirmation = () => {
	const [buttonText, setButtonText] = useState("Send again");
	const [buttonIcon, setbuttonIcon] = useState(
		<span className="material-symbols-rounded">send</span>,
	);
	function handleClick() {
		setButtonText("Sent!");
		setbuttonIcon(
			<span className="material-symbols-rounded">check_circle</span>,
		);
	}

	return (
		<Box>
			<Grid
				container
				spacing={4}
				sx={{
					"& .MuiInputBase-input.MuiOutlinedInput-input": {
						bgcolor: "background.level1",
					},
				}}
				display={"flex"}
				alignItems={"flex-end"}>
				<Grid
					item
					xs={12}>
					<Box
						display="flex"
						alignItems="center"
						flexDirection="row"
						flexWrap="nowrap"
						alignContent="center"
						justifyContent="center">
						<Typography
							fontWeight={700}
							variant={"h3"}
							align={"center"}>
							Check your mailbox&nbsp;
						</Typography>
						<span className="material-symbols-rounded material-symbols-h3">
							mark_email_unread
						</span>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					alignItems="center">
					<Typography
						variant={"h4"}
						color={"textPrimary"}
						align={"center"}>
						We've sent you an activation link.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					alignItems="center">
					<Typography
						variant={"h6"}
						color={"textPrimary"}
						align={"center"}>
						Please confirm your email by clicking on the activation link to
						start your trial.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					alignContent="center">
					<Typography
						variant={"body1"}
						color={"textPrimary"}
						align={"center"}>
						Activation emails may take up to 10 minutes to arrive. Please check
						your spam folder.
					</Typography>
				</Grid>{" "}
				<Grid
					item
					xs={12}
					alignContent="center">
					<Typography
						variant={"body1"}
						color={"textPrimary"}
						align={"center"}>
						Didn't receive an email?
					</Typography>
				</Grid>
				<Grid
					item
					xs={3}
					marginX={"auto"}>
					<Button
						variant="outlined"
						size="large"
						fullWidth
						onClick={handleClick}
						endIcon={buttonIcon}>
						{buttonText}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Confirmation;
